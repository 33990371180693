import React, { Component, Fragment } from "react";
import "../App.css";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import moment from "moment";
import Select from "react-select";
import LoadingOverlay from "react-loading-overlay";
import { getRequestParams } from "../../src/helpers/globalfunctions.js";

export class DashboardComponent extends Component {
  static displayName = DashboardComponent.name;
  constructor(props) {
    super(props);
    this.state = {
      currentTime: moment().format("HH:mm A"),
      dataSource: [],
      pendingTrains: [],
      trainNumber: "",
      platformNo: "",
      clearTrainNumber: "",
      accountId: sessionStorage.getItem("session_accountId"),
      locationId: sessionStorage.getItem("session_locationId"),
      stationCode: sessionStorage.getItem("session_stationCode"),
      stationName: sessionStorage.getItem("session_stationName"),
      userId: sessionStorage.getItem("session_userId"),
      // accountId: 5823,
      // locationId: 156,
      // stationCode: "CSMT",
      // stationName: "CSMT",
      // userId : 20517,
      showAssignPopup: false,
      loadPanelVisible: false,
      loadPanelPopupVisible: false,
      showAssignVehiclePopup: false,
      assignVehicleRowData: null,
      assignVehicleItem: null,
      assignVehicleDropdownList: [],
      errors: {
        trainNumber: "",
        assignVehicleNo: "",
      },
    };
    this.timeout = 20000;
    this.dashboardInterval = 0;
    this.timeLabelInterval = 0;
    this.handleShowAssignPopup = this.handleShowAssignPopup.bind(this);
    this.handleHideAssignPopup = this.handleHideAssignPopup.bind(this);
    this.handleAssignTrain = this.handleAssignTrain.bind(this);
    this.handleClearTrain = this.handleClearTrain.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    this.getPlatformIndicatorStatus(false);
    this.timeLabelInterval = setInterval(() => {
      this.setState({ currentTime: moment().format("HH:mm A") });
    }, 5000);
  }

  componentWillUnmount() {
    if (this.dashboardInterval != 0) {
      clearTimeout(this.dashboardInterval);
    }
    if (this.timeLabelInterval != 0) {
      clearTimeout(this.timeLabelInterval);
    }
  }

  getPlatformIndicatorStatus = async (isAutoRefresh) => {
    try {
      clearTimeout(this.dashboardInterval);
      let accountId = this.state.accountId;
      let locationId = this.state.locationId;
      let stationCode = this.state.stationCode;
      const requestParams = getRequestParams("GET");
      if (isAutoRefresh !== true) {
        this.setState({
          loadPanelVisible: true,
        });
      }
      const response = await fetch(
        process.env.REACT_APP_TRIPS_API_BASE_URL +
          "TrainTrip/PlatformIndicator/Status/" +
          accountId +
          "/" +
          locationId +
          "/" +
          stationCode,
        requestParams
      );
      const data = await response.json();
      this.setState({
        dataSource: data != null && data.payload.length > 0 ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getPlatformIndicatorStatus function",
        err
      );
      this.setState({ loadPanelVisible: false });
    }
    this.dashboardInterval = setTimeout(() => {
      this.getPlatformIndicatorStatus(true);
    }, this.timeout);
  };

  getPendingTrains = async () => {
    try {
      if (this.dashboardInterval != null) {
        clearTimeout(this.dashboardInterval);
      }
      let accountId = this.state.accountId;
      let locationId = this.state.locationId;
      let stationCode = this.state.stationCode;
      let platformNo = this.state.platformNo;

      const requestParams = getRequestParams("GET");
      this.setState({
        loadPanelVisible: true,
      });
      const response = await fetch(
        process.env.REACT_APP_TRIPS_API_BASE_URL +
          "TrainTrip/PendingTrains/" +
          accountId +
          "/" +
          locationId +
          "/" +
          stationCode +
          "/" +
          platformNo,
        requestParams
      );
      const data = await response.json();
      let list = [];
      if (data != null && data.payload.length > 0) {
        data.payload.map((item) =>
          list.push({ value: item.trainNumber, label: item.trainName })
        );
      }

      this.setState({
        pendingTrains: list, //data.payload,
        showAssignPopup: data.payload != null && data.payload.length != 0,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getPendingTrains function",
        err
      );
      this.setState({ loadPanelVisible: false });
    }
  };

  handleOnTrainChange = (e) => {
    this.setState({
      trainNumber: e.value,
    });
    let errors = this.state.errors;
    errors.trainNumber = "";
    this.setState({ errors: errors });
  };

  // handleOnTrainChange = selectedOption => {
  //   this.setState({ selectedOption });
  // };

  handleValidation = () => {
    let formIsValid = true;
    try {
      let errors = this.state.errors;
      errors.trainNumber = "";

      if (this.state.trainNumber === "") {
        formIsValid = false;
        errors.trainNumber = "Please select trian.";
      }
      this.setState({ errors: errors });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleValidation function",
        err
      );
      formIsValid = false;
    }
    return formIsValid;
  };

  handleAssignTrain = async () => {
    if (this.handleValidation()) {
      try {
        Swal.fire({
          html:
            "Are you sure, you want to assign <strong class='text-danger'>" +
            this.state.trainNumber.label +
            "</strong> <br/>to platform no : <strong class='text-danger'>" +
            this.state.platformNo +
            "</strong>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.dashboardInterval != null) {
              clearTimeout(this.dashboardInterval);
            }
            // e.preventDefault();
            var request = {};
            request.AccountId = this.state.accountId;
            request.LocationId = this.state.locationId;
            request.UserId = this.state.userId;
            request.StationCode = this.state.stationCode;
            request.PlatformNo = this.state.platformNo;
            request.TrainNumber = this.state.trainNumber.value;
            const requestParams = getRequestParams("POST", request);
            this.setState({ loadPanelPopupVisible: true });
            fetch(
              process.env.REACT_APP_TRIPS_API_BASE_URL +
                "TrainTrip/PlatformIndicator/AssignTrain",
              requestParams
            )
              .then((response) => {
                if (response.ok) return response.json();
              })
              .then((data) => {
                //this.dashboardInterval = setTimeout(() => { this.getPlatformIndicatorStatus(); }, this.timeout);
                this.setState({ loadPanelPopupVisible: false });
                if (data != null) {
                  Swal.fire({
                    icon: data.isSuccess ? "success" : "error",
                    title: data.isSuccess ? "Success" : "Oops...",
                    text:
                      "Train No : " +
                      this.state.trainNumber.value +
                      " has assigned to platform no : " +
                      this.state.platformNo +
                      " successfully.",
                  }).then((result) => {
                    if (data.isSuccess) {
                      if (result.isConfirmed) {
                        this.handleHideAssignPopup();
                        this.getPlatformIndicatorStatus(false);
                      }
                    }
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Somthing went wrong.",
                  });
                }
              });
          }
        });
      } catch (err) {
        console.log(
          "Error in " + this.displayName + ".handleAssignTrain function",
          err
        );
        this.setState({ loadPanelPopupVisible: false });
      }
    }
  };

  handleSubmitClearTrain = async () => {
    try {
      Swal.fire({
        html: "Are you sure, you want to clear train ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.dashboardInterval != null) {
            clearTimeout(this.dashboardInterval);
          }
          // e.preventDefault();
          var request = {};
          request.AccountId = this.state.accountId;
          request.LocationId = this.state.locationId;
          request.UserId = this.state.userId;
          request.StationCode = this.state.stationCode;
          request.PlatformNo = this.state.platformNo;
          request.TrainNumber = "";
          this.setState({ loadPanelVisible: true });
          const requestParams = getRequestParams("POST", request);
          fetch(
            process.env.REACT_APP_TRIPS_API_BASE_URL +
              "TrainTrip/PlatformIndicator/ClearTrain",
            requestParams
          )
            .then((response) => {
              if (response.ok) return response.json();
            })
            .then((data) => {
              //  this.dashboardInterval = setTimeout(() => { this.getPlatformIndicatorStatus(); }, this.timeout);
              this.setState({ loadPanelVisible: false });
              if (data != null) {
                Swal.fire({
                  icon: data.isSuccess ? "success" : "error",
                  title: data.isSuccess ? "Success" : "Oops...",
                  text:
                    "Train No : " +
                    this.state.clearTrainNumber +
                    " has clear from platform no : " +
                    this.state.platformNo +
                    " successfully.",
                }).then((result) => {
                  if (data.isSuccess) {
                    if (result.isConfirmed) {
                      this.getPlatformIndicatorStatus(false);
                    }
                  }
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Somthing went wrong.",
                });
              }
            });
        }
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleClearTrain function",
        err
      );
      this.setState({ loadPanelVisible: false });
    }
  };

  handleShowAssignPopup = (item) => {
    this.setState({ platformNo: item.platformNo }, () => {
      this.getPendingTrains();
    });
  };

  handleClearTrain = (item) => {
    this.setState(
      { platformNo: item.platformNo, clearTrainNumber: item.trainNumber },
      () => {
        this.handleSubmitClearTrain();
      }
    );
  };

  handleHideAssignPopup = () => {
    this.setState({
      showAssignPopup: false,
      pendingTrains: [],
      trainNumber: "",
      platformNo: "",
    });
  };

  handleRefreshPage = () => {
    this.getPlatformIndicatorStatus(false);
  };

  handleLogout = () => {
    sessionStorage.setItem("session_token", "");
    sessionStorage.setItem("session_userId", "");
    sessionStorage.setItem("session_userName", "");
    sessionStorage.setItem("session_name", "");
    sessionStorage.setItem("session_email", "");
    sessionStorage.setItem("session_accountId", "");
    sessionStorage.setItem("session_accountname", "");
    sessionStorage.setItem("session_locationId", "");
    sessionStorage.setItem("session_PageName", "Login");
    sessionStorage.setItem("session_stationCode", "");
    sessionStorage.setItem("session_stationName", "");
    sessionStorage.clear();
    window.location.href = "/login";
  };

  render() {
    return (
      <Fragment>
        <LoadingOverlay
          active={this.state.loadPanelVisible}
          spinner
          text="Request in process ... Please wait ..."
        >
          <Navbar
            bg="dark"
            expand="lg"
            className="d-flex justify-content-between"
          >
            <Navbar.Brand href="#home">
              <img
                src="/img/logo-sst-white.png"
                alt="SilverStar"
                width=""
                height=""
                className="silverstarLogo"
              />
            </Navbar.Brand>
            <Nav.Link onClick={this.handleLogout} className="text-white p-0">
              Logout
            </Nav.Link>
          </Navbar>
          <div className="title-station clearfix pt-2 pl-3 pb-2 pr-3">
            <div className="float-left text-white">
              <h1 className="h6 mb-0">
                Station -{" "}
                <span className="text-white">{this.state.stationName}</span>
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  onClick={this.handleRefreshPage}
                >
                  <i className="fa fa-refresh"></i>
                </button>
              </h1>
            </div>
            <div className="float-right text-white text-right">
              <h1 className="h6 mb-0">{this.state.currentTime}</h1>
              {/* <button type='button' className='btn btn-primary' onClick={this.handleLogout}>Logout</button> */}
            </div>
          </div>
          <div className="" style={{ height: "98%" }}>
            <div className="card-body p-0">
              <div className="table-responsive table-responsive-lg">
                <table className="table table-striped custom-table">
                  <thead className="thead-dark">
                    <tr>
                      <th>PF</th>
                      {/* <th>Train</th> */}
                      <th>Time</th>
                      <th>Destination</th>
                      <th className="text-center">Car</th>
                      <th className="text-center">Type</th>
                      <th className="text-center">
                        As/Un<br></br>Train
                      </th>
                      <th className="text-center">
                        As/Un<br></br>Vehicle
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.dataSource.map((item) => (
                      <tr key={item.platformNo}>
                        <td>{item.platformNo}</td>
                        {/* <td>{item.trainNumber}</td> */}
                        <td>{item.startTime}</td>
                        <td>
                          {item.endStationName}
                          <div className="vehicleNo text-muted">
                            {item.vehicleNo}
                          </div>
                        </td>
                        <td className="text-center" style={{ width: "1%" }}>
                          {item.coaches}
                        </td>
                        <td className="text-center" style={{ width: "1%" }}>
                          {item.speedType}
                        </td>
                        <td className="text-center">
                          <button
                            title="Assign Train"
                            type="button"
                            className="btn btn-success btn-md"
                            onClick={(e) => {
                              this.handleShowAssignPopup(item);
                            }}
                          >
                            <i className="fa fa-train"></i>
                          </button>{" "}
                          <button
                            title="Unassign Train"
                            type="button"
                            style={{
                              display:
                                item.trainNumber == null
                                  ? "none"
                                  : "inline-block",
                            }}
                            className="btn btn-danger btn-md"
                            onClick={(e) => {
                              this.handleClearTrain(item);
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </td>
                        <td className="text-center">
                          <button
                            title="Assign Vehicle"
                            type="button"
                            className="btn btn-success btn-md"
                            onClick={(e) => {
                              this.setState((prev) => ({
                                ...prev,
                                showAssignVehiclePopup: true,
                                assignVehicleRowData: item,
                              }));
                            }}
                          >
                            <i className="fa fa-car"></i>
                          </button>{" "}
                          <button
                            type="button"
                            title="Unassign Vehicle"
                            style={{
                              display:
                                item.vehicleId && item.vehicleNo
                                  ? "inline-block"
                                  : "none",
                            }}
                            className="btn btn-danger"
                            onClick={(e, param = { ...item }) => {
                              e.preventDefault();
                              debugger;
                              try {
                                Swal.fire({
                                  html: `Are you sure, you want to clear vehicle ?`,
                                  html: `Are you sure, you want to clear <strong class='text-danger'>
                                  ${param?.vehicleNo ?? ""}</strong> 
                                  <br/>Platform no : <strong class='text-danger'>
                                  ${
                                    param?.platformNo ?? ""
                                  }</strong><br/>Time : <strong class='text-danger'>
                                  ${param?.startTime ?? ""}</strong>
                                  <br/>Destination : <strong class='text-danger'>
                                  ${param?.endStationName ?? ""}</strong>`,
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes",
                                  allowOutsideClick: false,
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    if (this.dashboardInterval != null) {
                                      clearTimeout(this.dashboardInterval);
                                    }
                                    var request = {};
                                    request.AccountId = param.accountId;
                                    request.LocationId = param.locationId;
                                    request.UserId = this.state.userId;
                                    request.StationCode = param.stationCode;
                                    request.PlatformNo = param.platformNo;
                                    request.TrainNumber = param.trainNumber;
                                    request.vehicleId = param.vehicleId;
                                    request.vehicleNo = param.vehicleNo;
                                    this.setState({ loadPanelVisible: true });
                                    const requestParams = getRequestParams(
                                      "POST",
                                      request
                                    );
                                    fetch(
                                      process.env.REACT_APP_TRIPS_API_BASE_URL +
                                        "TrainTrip/PlatformIndicator/ClearVehicle",
                                      requestParams
                                    )
                                      .then((response) => {
                                        if (response.ok) return response.json();
                                      })
                                      .then((data) => {
                                        //  this.dashboardInterval = setTimeout(() => { this.getPlatformIndicatorStatus(); }, this.timeout);
                                        this.setState({
                                          loadPanelVisible: false,
                                        });
                                        if (data != null) {
                                          Swal.fire({
                                            icon: data.isSuccess
                                              ? "success"
                                              : "error",
                                            title: data.isSuccess
                                              ? "Success"
                                              : "Oops...",
                                            html: data.isSuccess
                                              ? `Vehicle # <strong class='text-danger'>${param.vehicleNo}</strong> has been cleared from platform no <strong class='text-danger'>${param.platformNo}</strong> successfully.`
                                              : `${data.message}`,
                                          }).then((result) => {
                                            if (data.isSuccess) {
                                              if (result.isConfirmed) {
                                                this.getPlatformIndicatorStatus(
                                                  false
                                                );
                                              }
                                            }
                                          });
                                        } else {
                                          Swal.fire({
                                            icon: "error",
                                            title: "Oops...",
                                            text: "Somthing went wrong.",
                                          });
                                        }
                                      });
                                  }
                                });
                              } catch (err) {
                                console.log(
                                  "Error in " +
                                    this.displayName +
                                    ".handleClearVehicle function",
                                  err
                                );
                                this.setState({ loadPanelVisible: false });
                              }
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </td>
                        {/* <td className='text-center'><button type='button' className='btn btn-primary btn-md' onClick={(e) => { this.handleShowAssignPopup(item) }}> Set</button></td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </LoadingOverlay>

        <Modal
          show={this.state.showAssignPopup}
          onHide={this.handleHideAssignPopup}
        >
          <Modal.Header closeButton>
            <Modal.Title>Platform No : {this.state.platformNo}</Modal.Title>
          </Modal.Header>
          <LoadingOverlay
            active={this.state.loadPanelPopupVisible}
            spinner
            text="Request in process ... Please wait ..."
          >
            <Modal.Body>
              <div className="form-label-group">
                {/* <select className="form-control" placeholder="Select Train" required="" autoFocus="" name="trainNumber" onChange={this.handleInputChange}>
                <option value="">Select Train</option>
                {
                  this.state.pendingTrains.map((item) => (
                    <option value={item.trainNumber}>{item.trainName}</option>
                  ))}
              </select> */}
                <Select
                  placeholder="Select Train"
                  options={this.state.pendingTrains}
                  value={this.state.trainNumber}
                  onChange={(value) => this.setState({ trainNumber: value })}
                  //styles={customTrainDDLStyles}
                />
              </div>
              {this.state.errors["trainNumber"].length > 0 && (
                <span className="error-msg">
                  {this.state.errors["trainNumber"]}
                </span>
              )}
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={this.handleHideAssignPopup}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleAssignTrain}
              >
                Assign Train
              </button>
            </Modal.Footer>
          </LoadingOverlay>
        </Modal>
        <Modal
          show={this.state.showAssignVehiclePopup}
          onHide={() => {
            debugger;
            let err = this.state.errors;
            err.assignVehicleNo = "";
            this.setState((prev) => ({
              ...prev,
              showAssignVehiclePopup: false,
              assignVehicleRowData: null,
              assignVehicleItem: null,
              errors: err,
            }));
          }}
          onShow={async () => {
            try {
              if (this.dashboardInterval != null) {
                clearTimeout(this.dashboardInterval);
              }
              const requestParams = getRequestParams("GET");
              this.setState((prev) => ({
                ...prev,
                loadPanelVisible: true,
              }));
              let apiUrl = `${process.env.REACT_APP_API_BASE_URL}VehicleLastStatusFetch/dropdown-list/${this.state.userId}/${this.state.accountId}/${this.state.locationId}`;
              const response = await fetch(apiUrl, requestParams);
              const data = await response.json();
              let assignVehicleDropdownList = [];
              if (data?.payload?.length > 0) {
                data.payload.map((item) =>
                  assignVehicleDropdownList.push({
                    value: item.vehicleId,
                    label: item.vehicleNo,
                    deviceTime: item.deviceTime,
                  })
                );
              }
              this.setState((prev) => ({
                ...prev,
                assignVehicleDropdownList: assignVehicleDropdownList,
                loadPanelVisible: false,
              }));
            } catch (err) {
              console.log(
                "Error in " +
                  this.displayName +
                  ".getVehicleDropdownList function",
                err
              );
              this.setState((prev) => ({
                ...prev,
                loadPanelVisible: false,
              }));
            }
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Platform No : {this.state.assignVehicleRowData?.platformNo}
            </Modal.Title>
          </Modal.Header>
          <LoadingOverlay
            active={this.state.loadPanelPopupVisible}
            spinner
            text="Request in process ... Please wait ..."
          >
            <Modal.Body>
              <div className="form-label-group">
                <Select
                  placeholder="Select Vehicle"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "grey" : "green",
                      color: state.isSelected ? "#212529" : "#fff",
                      padding: "0px",
                      boxShadow: "none",
                    }),
                  }}
                  options={this.state.assignVehicleDropdownList}
                  value={this.state.assignVehicleItem}
                  autoFocus
                  isClearable={true}
                  noOptionsMessage={({ inputValue }) =>
                    `No result found for "${inputValue}"`
                  }
                  onChange={(option) => {
                    try {
                      this.setState((prev) => ({
                        ...prev,
                        assignVehicleItem: option,
                      }));
                    } catch (err) {
                      console.log(
                        "Error in " +
                          this.displayName +
                          ".onVehicleSelectionChange function",
                        err
                      );
                    }
                  }}
                />
              </div>
              {this.state.errors["assignVehicleNo"].length > 0 && (
                <span className="error-msg">
                  {this.state.errors["assignVehicleNo"]}
                </span>
              )}
              {this.state?.assignVehicleItem?.deviceTime && (
                <div className="form-label-group">
                  Rake gps time #{" "}
                  <strong className="text-danger">
                    {this.state?.assignVehicleItem?.deviceTime ?? ""}
                  </strong>
                </div>
              )}
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  debugger;
                  let err = this.state.errors;
                  err.assignVehicleNo = "";
                  this.setState((prev) => ({
                    ...prev,
                    showAssignVehiclePopup: false,
                    assignVehicleRowData: null,
                    assignVehicleItem: null,
                    errors: err,
                  }));
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  try {
                    debugger;
                    let errors = this.state.errors;
                    errors.assignVehicleNo = "";
                    let isValid = true;
                    let vehicleId = this.state?.assignVehicleItem?.value ?? 0;
                    let vehicleNo = this.state?.assignVehicleItem?.label ?? "";
                    let gpsDateTime =
                      this.state?.assignVehicleItem?.deviceTime ?? "";
                    if (!vehicleId === 0) {
                      isValid = false;
                      errors.assignVehicleNo = "Please select vehicle.";
                    }
                    if (!gpsDateTime) {
                      isValid = false;
                      errors.assignVehicleNo =
                        "Gps time not available for this vehcile.";
                    }
                    this.setState((prev) => ({
                      ...prev,
                      errors: errors,
                    }));
                    if (isValid) {
                      Swal.fire({
                        html: `Are you sure, you want to assign <strong class='text-danger'>
                      ${vehicleNo}</strong> <br/>Platform no : <strong class='text-danger'>
                      ${
                        this.state.assignVehicleRowData?.platformNo ?? ""
                      }</strong><br/>Time : <strong class='text-danger'>
                      ${
                        this.state.assignVehicleRowData?.startTime ?? ""
                      }</strong>
                      <br/>Destination : <strong class='text-danger'>
                      ${
                        this.state.assignVehicleRowData?.endStationName ?? ""
                      }</strong>`,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes",
                        allowOutsideClick: false,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          if (this.dashboardInterval != null) {
                            clearTimeout(this.dashboardInterval);
                          }
                          var request = {};
                          request.AccountId = this.state.accountId;
                          request.LocationId = this.state.locationId;
                          request.UserId = this.state.userId;
                          request.StationCode =
                            this.state.assignVehicleRowData.stationCode;
                          request.PlatformNo =
                            this.state.assignVehicleRowData.platformNo;
                          request.TrainNumber =
                            this.state.assignVehicleRowData.trainNumber;
                          request.VehicleId = vehicleId;
                          request.VehicleNo = vehicleNo;
                          request.AssignedOn = moment().format(
                            "YYYY-MM-DD HH:mm:ss"
                          );
                          request.IndicatorTime =
                            this.state.assignVehicleRowData?.startTime ?? "";
                          request.GpsDateTime = gpsDateTime;
                          request.IsManualVehicleAssigned = true;
                          const requestParams = getRequestParams(
                            "POST",
                            request
                          );
                          this.setState((prev) => ({
                            ...prev,
                            loadPanelPopupVisible: true,
                          }));
                          fetch(
                            process.env.REACT_APP_TRIPS_API_BASE_URL +
                              "TrainTrip/PlatformIndicator/SetVehicle",
                            requestParams
                          )
                            .then((res) => {
                              if (res.ok) return res.json();
                            })
                            .then((data) => {
                              this.setState((prev) => ({
                                ...prev,
                                loadPanelPopupVisible: false,
                              }));
                              if (data != null) {
                                Swal.fire({
                                  icon: data.isSuccess ? "success" : "error",
                                  title: data.isSuccess ? "Success" : "Oops...",
                                  html: data.isSuccess
                                    ? `Vehicle # <strong class='text-danger'>${vehicleNo}</strong> has assigned to platform no <strong class='text-danger'>${this.state.assignVehicleRowData.platformNo}</strong> successfully.`
                                    : `Unable to assign vehicle # <strong class='text-danger'>${vehicleNo}</strong></br>${data.message}`,
                                }).then((result) => {
                                  if (data.isSuccess) {
                                    if (result.isConfirmed) {
                                      let err = this.state.errors;
                                      err.assignVehicleNo = "";
                                      this.setState((prev) => ({
                                        ...prev,
                                        showAssignVehiclePopup: false,
                                        assignVehicleRowData: null,
                                        assignVehicleItem: null,
                                        errors: err,
                                      }));
                                      this.getPlatformIndicatorStatus(false);
                                    }
                                  }
                                });
                              }
                            });
                        }
                      });
                    }
                  } catch (err) {
                    console.log(
                      "Error in " +
                        this.displayName +
                        ".onAssignVehicle function",
                      err
                    );
                  }
                }}
              >
                Assign Vehicle
              </button>
            </Modal.Footer>
          </LoadingOverlay>
        </Modal>
      </Fragment>
    );
  }
}
