import React, { Component, Fragment } from 'react';
import { Route } from 'react-router';

import { LoginComponent } from './components/LoginComponent';
import { DashboardComponent } from './components/DashboardComponent';
import { Layout } from './components/Layout';

import 'bootstrap/dist/css/bootstrap.min.css';

export default class App extends Component {
  static displayName = App.name;
  render() {
    return (
      <Fragment>
        <Route exact path='/' component={LoginComponent} />
        <Route path='/login' component={LoginComponent} />
        <Route path='/dashboard' component={DashboardComponent} />
      </Fragment>
    );
  }
}